import httpService from "@/api/http";

const courseService = {
  getCourse(name) {
    return httpService.get(["/v2/courses", name].join("/"));
  },
  getChapter(cId) {
    return httpService.get(["/v2/courses", "chapters", cId].join("/"));
  },
  getCompletedChapters(courseName) {
    return httpService.get(
      ["/v2/courses", courseName, "completed-chapters"].join("/")
    );
  },
  getCompletedAchievements() {
    return httpService.get("/v2/courses/completed-achievements");
  },
  getApplicableExam(courseName) {
    return httpService.get(
      ["/v2/courses", courseName, "applicable-exam"].join("/")
    );
  },
  getExamQuestions(courseName) {
    return httpService.get(
      ["/v2/courses", courseName, "exam-questions"].join("/")
    );
  },
  saveExamResponse(courseName, data) {
    const httpOptions = {
      data,
    };
    return httpService.post(
      ["/v2/courses", courseName, "exam-questions"].join("/"),
      httpOptions
    );
  },
  getUserCertUrl(courseName, userId) {
    return httpService.get(
      ["/v2/courses", courseName, "certificate", userId, "download"].join("/")
    );
  },
  getCourses() {
    return httpService.get("/v2/courses");
  },
  getLabsByDifficulty(difficulty) {
    return httpService.get(["/v2/courses/labs", difficulty].join("/"));
  },
  getLabsByChapterId(chapterId) {
    const url = ["/v2/courses/chapters", chapterId, "labs"];
    return httpService.get(url.join("/"));
  },
};

export default courseService;
