<template>
    <div class="quiz-modal d-flex w-100 h-100 flex-column">
        <div class="d-flex justify-content-start align-items-start w-100 pt-2 m-2">
            <i class="fa-solid fa-xmark fa-2xl" style="cursor: pointer" @click="selfClose()"></i>
        </div>
        <div id="modal-header" class="d-flex justify-content-center align-items-center w-100 py-5 px-5">
            <div id="resp-count" class="w-100">
                <h2 class="m-0 p-0 main-title-2 w-100">{{ cQuestionIndex }}/{{ qLength }}</h2>
            </div>
            <div id="modal-title" class="w-100 d-none d-md-block">
                <h2 class="m-0 p-0 main-title-2 w-100 text-center">
                    {{ title }}
                </h2>
            </div>
            <div id="remaining-time" class="w-100">
                <h2 class="m-0 p-0 main-title-2 w-100 text-right">
                    {{ time() }}
                </h2>
            </div>
        </div>
        <div id="modal-content" class="d-flex flex-column justify-content-start align-items-start w-100 h-100">
            <div id="current-q" class="w-100">
                <p class="m-0 p-0">
                    {{ question.question }}
                </p>
            </div>
            <div class="d-flex justify-content-between align-items-start" id="q-responses"
                v-for="(response, key) of question.options" :key="key" @click="setKey(key)"
                :class="{ 'active': rSelectedKey == key }">
                <p>{{ response }}</p>
                <i v-if="rSelectedKey == key && loading"
                    class="fas fa-spinner fa-spin d-flex flex-row-reverse justify-content-center align-items-center"></i>
            </div>
            <div class="q-qid-container d-md-flex justify-content-center align-items-center w-100 d-none">
                <div class="q-qid bg-medium" v-for="idx in qLength" :key="idx">
                    <p :class="{ 'active': (cQuestionIndex + 1) == idx || idx < (cQuestionIndex + 1) }">{{ idx }}
                    </p>
                </div>
            </div>
            <div id="arrows-container" class="d-flex justify-content-center align-items-center w-100 p-5">
                <h2 class="main-title-2 m-0 p-0">{{ cQuestionIndex + 1 }}</h2>
                <div class="arrow-right d-flex justify-content-center align-items-center" @click="nextQuestion()"
                    v-if="cQuestionIndex != qLength - 1">
                    <div class="arrow right"></div>
                </div>
                <div id="complete-mark" class="d-flex justify-content-center align-items-center"
                    v-if="cQuestionIndex == qLength - 1" @click="completeQuiz()">
                    <i class="fa-solid fa-check fa-2xl"></i>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "QuizModal",
    computed: {
        question() {
            const qIndex = this.cQuestionIndex;
            const questions = this.questions;
            if (questions.length === 0) return null;

            const question = questions[qIndex];
            return question
        },
    },
    created() {
        let questions = this.questions
        if (questions.length === 0) return;
        let qIndex = 0;

        questions = questions.sort((a, b) => {
            return a["answered"] < b["answered"]
        })

        for (const question of questions) {
            const answered = question["answered"];
            if (answered) {
                qIndex += 1;
                continue
            }
            this.cQuestionIndex = qIndex;
            break;
        }

        this.qLength = questions.length;
        this.cQuestionId = questions[qIndex].id;
    },
    data() {
        return {
            cQuestionIndex: 0,
            cQuestionId: null,
            qLength: 0,
            rSelectedKey: null,

            loading: false,

            timerCount: this.timestamp() - new Date().getTime(),
        }
    },
    methods: {
        async completeQuiz() {

            const key = this.rSelectedKey;
            if (!key) {
                this.flashError("You need to choose an answer to proceed");
                return;
            }

            await this.saveResponse()
            window.location.reload()
        },
        selfClose() {
            this.$emit("close");
        },
        time() {
            const timestamp = this.timerCount;
            const date = new Date(timestamp);
            const minute = date.getMinutes();
            const second = Math.max(Math.floor(date.getSeconds() % 60), 0);
            return [
                minute < 10 ? "0" + String(minute) : minute,
                second < 10 ? "0" + String(second) : second,
            ].join(":")
        },
        setIndex(idx) {
            this.cQuestionIndex = idx;
        },
        setKey(key) {
            this.rSelectedKey = key;
        },
        async nextQuestion() {
            const index = this.cQuestionIndex;
            const qLength = this.qLength;
            await this.saveResponse()

            this.cQuestionIndex = Math.min(qLength, (index + 1));

            const question = this.question;
            if (!question) return;
            this.cQuestionId = question.id;

            this.rSelectedKey = null;
        },
        async saveResponse() {
            const question = this.question;
            if (!question) return;

            const questionId = this.cQuestionId;
            const responseId = this.rSelectedKey;

            this.loading = true;
            const data = {
                "questionId": questionId,
                "responseId": responseId,
            };
            return await this.saveResponseCallback(data)
                .then((response) => {
                    if (!response.data.ok) {
                        this.flashError("There was an error saving your data")
                        return
                    }
                })
                .catch((err) => {
                    const error = err.response;
                    const manageableError = !!error.data && !!error.data.errorDescription;
                    if (manageableError) {
                        const message = error.data.errorDescription;
                        this.flashError(message)
                    }
                })
                .finally(() => {
                    this.loading = false;
                })
        },
    },
    watch: {
        timerCount: {
            handler(value) {
                const validated = Math.max(0, value);

                if (validated > 0) {
                    setTimeout(() => { this.timerCount -= 1000 }, 1000)
                }
            },
            immediate: true
        },
    },
    props: {
        questions: {
            type: Array,
            required: true
        },
        title: {
            type: String,
            required: true,
        },
        timestamp: {
            type: Function,
            required: true,
        },
        completeCallback: {
            type: Function,
            required: true
        },
        saveResponseCallback: {
            type: Function,
            required: true
        }
    },
}
</script>
<style scoped lang="scss">
@import "../assets/css/colors.scss";

.quiz-modal {
    --max-height: 800px;
    --shadow-bg: $neutral--darkest;

    box-shadow: var(--shadow-bg) 0px 5px 15px;
    background-color: $neutral--darkest !important;
    overflow: scroll;

    max-height: var(--max-height);

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

h2 {
    font-size: 30px;
    font-weight: 900;
    letter-spacing: 2px;
}

#current-q {
    background-color: $neutral--medium;
    border: 1px solid $neutral--medium;

    p {
        margin: 0 !important;
        padding: 10px !important;
        font-size: 25px;
        text-align: center;
        line-height: 1;
        list-style-type: none !important;
    }

    &:nth-child(even) {
        background-color: $neutral--darker !important;
    }

    &:nth-child(odd) {
        background-color: $neutral--dark !important;
    }

}

#q-responses {
    margin: 0;
    padding: 0;
    width: 100%;
    border: 1px solid $neutral--dark;
    transition: height 0.5s ease-in-out,
        width 0.5s ease-in-out,
        padding 0.5s ease-in-out,
        margin 0.5s ease-in-out;

    padding: 20px;
    cursor: pointer;

    p {
        margin: 0;
        padding: 0;
        font-size: 16px;
        line-height: 1;
        width: 100% !important;
        font-weight: 400;
    }

    i {
        margin: 0;
        padding: 0;
        line-height: 1;
    }

    &.active {
        background-color: $neutral--dark;
    }

    &:hover {
        background-color: $neutral--dark;
    }

}

.q-qid {
    margin-top: 10px;
    margin-bottom: 10px;
}

.q-qid p {
    margin: 0;
    padding: 10px;
    line-height: 1;
    width: 100% !important;
    border: 1px solid $neutral--dark;
    cursor: pointer;

    &.active {
        color: #000;
        background-color: $soft-green !important;
    }

    &:hover {
        color: #000;
        background-color: $soft-green !important;
    }
}

.arrow {
    border: solid white;
    border-width: 0 5px 5px 0;
    display: inline-block;
    padding: 10px;
}

.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.arrow-right,
.arrow-left {
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    margin: 0px 10px;

    &:hover {
        background-color: $neutral--dark !important;
    }
}

#complete-mark {
    margin: 0px 10px;
    padding: 20px;

    width: 50px;
    height: 50px;

    border-radius: 50%;
    color: $soft-green !important;
    cursor: pointer !important;

    &:hover {
        background-color: $neutral--dark !important;
    }
}
</style>
