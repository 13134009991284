<template>
  <div class="row m-0 p-0">
    <div class="confirm-overlay-wrapper" v-if="confirmationModal">
      <div class="confirm-overlay-background"></div>
      <div class="confirm-overlay">
        <ConfirmationModal class="confirmation-modal" :confirmAction="modalConfigAction"
          :closeAction="() => confirmationModal = false" modalTitle="User company config">
          <h2>Would you like to update the Learning Path notification delay?</h2>
          <div class="form-group">
            <label for="frequency">Frequency: </label>
            <!-- <input class="form-control" min="300000" type="number" name="frequency" id="frequency" -->
            <!--   v-model="frequencyMs"> -->
            <select class="form-control" name="frequency" id="frequency" v-model="frequencyMs">
              <option :value="freq" :key="idx" v-for="(freq, idx) of frequencies">{{ freq }} min</option>
            </select>
          </div>
        </ConfirmationModal>
      </div>
    </div>
    <div class="col-12 col-md-6 position-relative left-side">
      <div class="d-flex justify-content-between align-items-center flex-row">
        <h2 class="main-title-2 m-0 p-0">Discover Your Path</h2>
        <div @click="openLpStartModal()" id="brain-icon-container"
          class="pointer border p-1 mx-1 d-flex flex-row justify-content-center align-items-center"
          style="white-space: nowrap">
          <h2 class="main-title-2 m-0 p-0">learning path</h2>
          <div class="ml-2">
            <i id="brain-icon" class="fa-solid fa-brain fa-xl mr-1"></i>
          </div>
        </div>
      </div>
      <div id="courses-cards" v-if="courses !== null"
        class="d-flex justify-content-start align-items-start flex-column w-100 mt-1 mb-0 mx-0 p-0">
        <div id="course-card" v-for="(course) of courses" :key="course.id" class="d-flex flex-column mt-2 w-100"
          @click="changeDetailsIndex(course.id)">
          <div id="course-card-content" class="d-flex flex-row">
            <div id="course-card-image-container">
              <img :src="course.image" :alt="course.name">
            </div>
            <div id="course-card-main" class="d-flex flex-column text-start w-100">
              <div class="ml-md-2 d-flex flex-row justify-content-between">
                <div id="course-main">
                  <h2 class="m-0 p-0 main-title-2">{{ course.name }}</h2>
                  <p class="m-0 p-0">{{ course.description }}</p>
                </div>
              </div>
              <div id="course-card-footer">
                <div class="rounded-pill" id="course-completion-bar">
                  <div :style="`--progress: ${course.completion}%`" id="course-completion-bar--progress"></div>
                </div>
                <p class="m-0 p-0 ml-2 mb-2">
                  {{ course.completion }}% Complete
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-md-flex justify-content-md-center align-items-md-center col-12 col-md-6 right-side"
      :class="{ 'active': courseActive }" id="right" v-if="courses !== null && chosen !== null">
      <div class="learningpath-notifications" :class="{ 'active': isNotification }">
        <div v-if="notification !== null" class="learningpath-notifications-wrapper">
          <div @click="goToChapter(notification.id)" class="learningpath-notifications-content">
            <div class="learningpath-notification-chapter-name w-100">
              {{ notification.name }}
            </div>
            <div class="learningpath-notification-icons d-flex justify-content-end align-items-center">
              <i class="fa-regular fa-folder-open"></i>
              <i @click="resetNotification()" class="fa-solid fa-xmark fa-lg ml-2 pointer"></i>
            </div>
          </div>
        </div>
      </div>
      <div id="course-right-details" class="d-flex justify-content-start flex-column position-relative">
        <div class="technical-quiz-wrapper" v-if="technicalApplicable !== null && technicalApplicable.applicable">
          <div class="technical-quiz-content">
            <div class="technical-banner-container">
              <h2 class="main-title-2">technical test available</h2>
            </div>
            <div class="technical-lab-btns-container">
              <template>
                <px-button @click.native="$router.push(`/event/${technicalApplicable.eventId}`)"
                  v-if="technicalApplicable.end !== null" class="px-button--secondary w-100">Your technical
                  event is already running</px-button>
                <px-button v-if="technicalApplicable.end === null" @click.native="startTechnicalEvent"
                  class="px-button--success w-100">start</px-button>
              </template>
            </div>
          </div>
        </div>
        <div id="course-right-banner">
          <img :src="chosen.image" :alt="chosen.name">
        </div>
        <div id="course-right-details-overlay">
          <i style="z-index: 100;cursor: pointer;"
            class="d-md-none fa-solid fa-xmark fa-2xl m-0 p-0 position-absolute mt-3 mx-2"
            @click="courseActive = false"></i>
          <div id="course-right-details-wrapper" class="d-flex flex-row justify-content-start align-items-end">
            <div id="course-right-details-main" class="d-flex flex-column w-100">
              <div id="course-right-details-main-title" class="p-2">
                <h1 class="main-title-1 text-uppercase">{{ chosen.name }}</h1>
              </div>
              <div id="course-right-details-type" class="d-flex justify-content-start flex-row p-2">
                <div id="course-right-details-type-logo"
                  class="d-flex justify-content-center align-items-center flex-row p-2">
                  <img :src="chosen.image" :alt="chosen.name">
                  <p class="m-0 p-0 pl-2">{{ chosen.details.type }}</p>
                </div>
              </div>
              <div id="course-right-details-description" class="mt-5 p-2">
                <h2 class="main-title-2 p-0 m-0">Course Overview</h2>
                <p class="m-0 p-0">{{ chosen.description }}</p>
              </div>
              <div id="course-right-details-footer"
                class="w-100 p-2 d-flex justify-content-center align-items-center flex-row">
                <px-button class="px-button--secondary outline w-100 mx-1" @click.native="getUserStats()">user
                  stats</px-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <right-menu-overlay overlayOpenVar="userStatsOverlayOpen" loadingVar="userStatsLoading" v-if="userStats !== null">
      <template v-slot:overlay-content>
        <div id="xmark-wrapper" class="d-flex flex-column w-100">
          <div id="xmark-icon-container" class="border p-2 rounded-circle">
            <i @click="toggleOverlay('userStatsOverlayOpen', { close: true })" class="fa-solid fa-xmark fa-2xl"></i>
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center flex-column w-100 h-full mt-3">
          <div id="stats-profile-pic" class="d-flex justify-content-center align-items-center">
            <img :src="userStats.profile_pic" :alt="userStats.username">
          </div>
          <div id="stats-user-details" class="d-flex justify-content-center flex-column w-100 align-items-center mt-3">
            <h2 class="main-title-2">{{ userStats.username }}</h2>
            <p id="creation-date">User created: {{ userStats.created.split("T").join(" ") }}</p>
            <div id="stats-user-progress" class="d-flex flex-row justify-content-center align-items-center">
              <div class="user-ranking border p-3 mx-1">
                <i class="fa-solid fa-chart-simple"></i>
                {{ userStats.rank }}°
              </div>
              <div class="user-points border p-3 mx-1">
                <i class="fa-solid fa-trophy"></i>
                {{ userStats.points }}
              </div>
              <div class="user-flags border p-3 mx-1">
                <i class="fa-solid fa-flag"></i>
                {{ userStats.flags + userStats.event_flags }}
              </div>
            </div>
            <div class="d-flex justify-content-center align-items-center flex-column w-100 h-full position-relative">
              <div id="stats-user-tabs" class="d-flex flex-row w-100 justify-content-around mt-5">
                <div id="stats-user-tab" class="text-center w-100" v-for="(tab, idx) of tabs" :key="idx"
                  :class="{ 'active': activeTabIndex === idx }" @click="changeActiveTab(idx)">
                  <span id="tab-item" class="w-100">{{ tab }}</span>
                </div>
              </div>
              <div id="stats-courses"
                class="d-flex flex-row justify-content-start align-items-center flex-column w-100">
                <template v-if="tabs[activeTabIndex] === 'course' && userStats && selectedCourseChapters">
                  <template v-if="Object.keys(selectedCourseChapters).length > 0">
                    <div class="d-flex justify-content-between align-items-center m-0 p-0 p-2 w-100 menu-item"
                      v-for="(chapter, key, idx) of selectedCourseChapters" :key="idx">
                      <div id="course-menu-item" class="d-flex flex-row justify-content-start align-items-center w-100">
                        <h2 class="m-0 p-0 main-title-1 w-100">{{ key }}</h2>
                      </div>
                      <div id="completion-bars-wrapper"
                        class="w-100 d-none d-md-flex flex-row justify-content-end align-items-end">
                        <div id="completion-bars-container"
                          class="d-flex justify-content-between align-items-center w-100 my-1">
                          <i class="fa-solid fa-computer"></i>
                          <div id="completion-bar" class="d-flex flex-row w-100">
                            <div :style="`--completion-width: ${chapter.completion.labs}%`"
                              id="completion-bar--progress">
                            </div>
                            <div id="completion-text">{{ chapter.completion.labs }}%</div>
                          </div>
                        </div>
                        <div id="completion-bars-container"
                          class="d-flex justify-content-between align-items-center w-100 my-1">
                          <i class="fa-solid fa-pen-nib"></i>
                          <div id="completion-bar" class="d-flex flex-row w-100">
                            <div :style="`--completion-width: ${chapter.completion.quiz}%`"
                              id="completion-bar--progress">
                            </div>
                            <div id="completion-text">{{ chapter.completion.quiz }}%</div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </template>
                  <template v-if="Object.keys(selectedCourseChapters).length == 0">
                    <div class="d-flex justify-content-center align-items-center w-100 h-100 my-5">
                      <p style="line-height: 1;font-weight: 100; font-size: 20px;">
                        This course doesn't have any chapter yet. Please try again later
                      </p>
                    </div>
                  </template>
                </template>
                <template v-if="tabs[activeTabIndex] === 'labs'">
                  <div id="labs-container"
                    class="d-flex flex-column justify-content-start align-items-center w-100 h-100 mh-100">
                    <div v-if="labLoading"
                      class="d-flex flex-row justify-content-center align-items-center w-100 h-100 mh-100 my-5">
                      <LocalSpinner style="--size: 40px"></LocalSpinner>
                    </div>
                    <template v-if="Object.keys(labs).length > 0">
                      <div class="m-0 p-0 p-2 w-100 menu-item" v-for="(lab, labId) of labs" :key="labId"
                        @click="selectLabId(labId)">
                        <div class="d-flex flex-row justify-content-start align-items-center w-100">
                          <i class="fa-solid fa-check mr-2 my-0 rounded-circle bg-success p-1" v-if="lab.solved"></i>
                          <i class="fa-solid text-danger mr-2 my-0 rounded-circle fa-xl fa-droplet p-1"
                            v-if="lab.firstblood"></i>
                          <h2 class="m-0 p-0" id="lab-name">{{ lab.name }}</h2>
                          <div id="lab-arrow" class="text-right pr-3 m-0 p-0">
                            <i class="arrow right"></i>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-if="Object.keys(labs).length === 0 && !labLoading">
                      <div class="d-flex justify-content-center align-items-center w-100 h-100 my-5">
                        <p style="line-height: 1;font-weight: 100; font-size: 20px;" class="m-0 p-0">This course doesn't
                          have any labs
                          yet.
                          Please try again later</p>
                      </div>
                    </template>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
    </right-menu-overlay>

    <right-menu-overlay overlayOpenVar="labOverlay" loadingVar="labOverlayLoading">
      <template v-slot:overlay-content v-if="selectedLab !== null">
        <div id="xmark-wrapper-lab" class="d-flex flex-column w-100">
          <div @click="toggleOverlay('labOverlay', { close: true })" id="xmark-icon-container"
            class="border p-2 rounded-circle">
            <i class="fa-solid fa-xmark fa-2xl"></i>
          </div>
        </div>
        <div class="d-flex justify-content-center flex-column align-items-center w-100 h-100 m-0 p-0 position-relative">
          <div id="graph-overlay" :class="{ 'darker': labGraph.isEmpty() }">
            <div id="graph-overlay-wrapper" v-if="labGraph.isEmpty()">
              <div id="graph-overlay-content">
                <h2 class="main-title-2">This lab hasn't been solved yet.</h2>
              </div>
            </div>
          </div>
          <v-chart :options="labGraph" class="graphs w-100"></v-chart>
        </div>
        <div id="lab-name-overlay" class="d-flex justify-content-between align-items-center w-100 m-0 p-0 flex-row">
          <div id="lab-name-overlay-content" class="d-flex justify-content-start align-items-center m-0 p-0 my-4">
            <i class="fa-solid fa-check mr-4 my-0 rounded-circle bg-success p-1" v-if="selectedLab.solved"></i>
            <h2 class="m-0 p-0 main-title-2">{{ selectedLab.name }}</h2>
          </div>
        </div>
        <div id="user-lab-data" class="d-flex justify-content-center align-items-center flex-column">
          <div
            class="mt-2 border-bottom border-top w-100 flex-row d-flex justify-content-start align-items-center m-0 p-0"
            id="user-lab-stats-banner">
            <div id="user-lab-stats-profile-pic" class="d-flex justify-content-center align-items-center">
              <img :src="userStats.profile_pic" :alt="userStats.username">
            </div>
            <div id="user-lab-stats-username" class="d-flex justify-content-center align-items-center">
              <h2 class="pl-2 m-0 p-0 main-title-2">{{ userStats.username }} - lab stats</h2>
            </div>
          </div>
          <div id="user-lab-stats-details" class="d-flex justify-content-center align-items-center flex-column w-100">
            <div class="d-flex justify-content-between align-items-center menu-item flex-row w-100 p-2">
              <h2 id="lab-detail-text" class="main-title-2 m-0 p-0">Created</h2>
              <p class="m-0 p-0 pr-4">{{ selectedLab.created.replace("T", " ") }}</p>
            </div>
            <div class="d-flex justify-content-between align-items-center menu-item flex-row w-100 p-2">
              <h2 id="lab-detail-text" class="main-title-2 m-0 p-0">Description</h2>
              <p class="m-0 p-0 pr-4">{{ selectedLab.description == "" ? "No Description Yet" : selectedLab.description
                }}</p>
            </div>
            <div class="d-flex justify-content-between align-items-center menu-item flex-row w-100 p-2">
              <h2 id="lab-detail-text" class="main-title-2 m-0 p-0">Difficulty</h2>
              <p class="m-0 p-0 pr-4">{{ selectedLab.difficulty }}</p>
            </div>
            <div class="d-flex justify-content-between align-items-center menu-item flex-row w-100 p-2">
              <h2 id="lab-detail-text" class="main-title-2 m-0 p-0">Times Started</h2>
              <p class="m-0 p-0 pr-4">{{ selectedLab.started_tracking.times_started }}</p>
            </div>
            <div class="d-flex justify-content-between align-items-center menu-item flex-row w-100 p-2"
              v-if="selectedLab.solved">
              <h2 id="lab-detail-text" class="main-title-2 m-0 p-0">Times Started Before Flag</h2>
              <p class="m-0 p-0 pr-4">{{ selectedLab.started_tracking.until_flag }}</p>
            </div>
            <div class="d-flex justify-content-between align-items-center menu-item flex-row w-100 p-2"
              v-if="selectedLab.solved">
              <h2 id="lab-detail-text" class="main-title-2 m-0 p-0">Solved in</h2>
              <p class="m-0 p-0 pr-4">{{ selectedLab.solving_time.days }} days, {{ selectedLab.solving_time.minutes &
                60 }} minutes </p>
            </div>
            <div class="d-flex justify-content-between align-items-center menu-item flex-row w-100 p-2"
              v-if="selectedLab.solved">
              <h2 id="lab-detail-text" class="main-title-2 m-0 p-0">Flag submitted</h2>
              <p class="m-0 p-0 pr-4">{{ selectedLab.flag.replace("T", " ") }}</p>
            </div>
          </div>
        </div>
      </template>
    </right-menu-overlay>

  </div>
</template>

<script>
import PxButton from "@/components/PxButton";
import companyService from "@/api/company.js";
import userService from "@/api/user.js";
import coursesService from "@/api/courses";
import RightMenuOverlay from "@/components/RightMenuOverlay";
import { mapState } from "vuex";
import ECharts from "vue-echarts";
import SurveyModal from "@/components/SurveyModal";
import LearningPathModal from "@/components/LearningPathModal";
import LocalSpinner from "@/components/LocalSpinner";
import ConfirmationModal from "@/components/ConfirmationModal";

export default {
  components: {
    PxButton,
    RightMenuOverlay,
    ConfirmationModal,
    LocalSpinner,
    "v-chart": ECharts
  },
  data() {
    return {
      ws: this.$parent.$data.ws,
      wsInterval: null,
      confirmationModal: false,
      frequencyMs: 15,
      frequencies: [
        60,
        30,
        15,
      ],
      isNotification: false,
      notification: null,
      technicalApplicable: null,
      selectedLab: null,
      courseActive: false,
      labLoading: false,
      startingInterval: null,
      labGraph: {
        color: "rgb(114, 253, 133)",
        isEmpty() {
          const [series] = this.series;
          const xAxis = this.xAxis;

          return series.data.length === 0 && xAxis.data.length === 0 || this.empty;
        },
        prepareEmpty: function ({ vm = null }) {
          const xAxis = Array(7).fill(null).map((_, i) => {
            let user = "user-" + (i + 1);
            if (i === 4) user = vm.user.username;
            return user;
          });

          const series = Array(7).fill(null).map(() => Math.floor(Math.random() * 1000))
          return [xAxis, series]
        },
        prepare: function (data, { vm = null }) {
          this.empty = false;
          const xAxis = this.xAxis;
          const series = this.series;

          const xAxisValues = Object.keys(data);
          const seriesValues = Object.values(data);

          xAxis["data"] = [];
          series[0]["data"] = []

          xAxis["data"] = xAxisValues;
          series[0]["data"] = seriesValues

          this.xAxis = xAxis;
          this.series = series;

          if (this.isEmpty()) {
            const [axis, serie] = this.prepareEmpty({ vm });
            this.xAxis["data"] = axis;
            this.series[0]["data"] = serie;
            this.empty = true;
            return
          }
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            textStyle: { color: "#fff" },
          },
          data: []
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            textStyle: { color: "#fff" },
            formatter: function (value) {
              const time = new Date(value * 1000).toISOString();
              return time.split("T")[0];
            }
          },
        },
        series: [
          {
            data: [],
            type: 'bar'
          }
        ]
      },
      tabs: ["course", "labs"],
      activeTabIndex: 0,
      labSelectedId: null,
      labSelectedData: null,
      labOverlay: false,
      labOverlayLoading: false,
      userStats: null,
      detailsIndex: 0,
      courses: null,
      isActive: false,
      courseId: null,
      courseName: null,
      courseLoading: false,
      courseOverlayOpen: false,
      courseSelected: null,
      courseChapter: null,
      chapterName: null,
      showChapterContent: false,
      userStatsOverlayOpen: false,
      userStatsLoading: false,
      userCourses: null,
      labs: [],
    }
  },
  computed: {
    unsolvedLabs() {
      return this.filterLabs(false);
    },
    solvedLabs() {
      return this.filterLabs(true);
    },
    getLabDetailsObject() {
      const lab = this.selectedLab;
      if (!lab) return;
      const obj = {
        "created": lab.created.replace("T", " "),
        "description": lab.description,
        "times started": lab.started_tracking.times_started
      }

      if (!lab.started_tracking.until_flag) {
        return obj
      }
      obj["times started before flag"] = lab.started_tracking.until_flag
      return obj
    },
    chosen() {
      const courseName = this.courseName;
      if (!courseName) return null;
      const [course] = this.courses.filter(course => course.name === courseName);
      return course
    },
    selectedCourseChapters() {
      const stats = this.userStats;
      if (!stats) {
        return [];
      }

      const courses = this.userCourses;
      if (!courses) return [];
      const length = Object.keys(courses).length;
      if (length === 0) {
        return [];
      }

      const courseName = this.courseSelected;
      if (!courseName) return [];
      const course = courses[courseName];

      if (length === 0 || !course) {
        return [];
      }
      const chapters = course["chapters"];
      return chapters;
    },
    ...mapState({
      user: state => state.user
    })
  },
  beforeCreate() {
    this.$parent.breadcrumbs = [{ name: "Company > Learning Path", navigation: "/learning-paths" }];
  },
  created() {

    this.ws.onmessage = (event) => {
      if (event.data === "pong") {
        console.log("Received PONG")
        return
      }

      let data = String(event.data);
      if ((!data.startsWith("{") || !data.endsWith("}")) && (!data.startsWith("[") || !data.endsWith("]"))) {
        const message = JSON.stringify({ "type": "DATA" })
        this.ws.send(message);
        return
      }

      const msg = JSON.parse(data);

      if (msg.type == "NOTIFY" && !this.isNotification) {

        this.isNotification = true

        const suggestions = JSON.parse(msg.message);
        const chapters = suggestions.chapters;
        const length = chapters.length;

        const message = chapters[Math.floor(Math.random() * length)];

        this.notification = message;

      } else if (msg.type == "FREQUPDATE") {
        const message = msg.message;
        this.flashWarning(message);
      } else if (msg.type == "FREQ") {
        this.frequencyMs = Number.parseInt(msg.frequency);
        const isDefault = msg.default;

        if (isDefault) this.confirmationModal = true;

        clearInterval(this.wsInterval);
        this.setupWsInterval();
      }

    };

    this.$parent.loading = true;

    this.getAllData().then(async ([courses, technical]) => {
      const coursesStatus = courses.status;

      if (coursesStatus !== "fulfilled") {
        this.flashError("There was an error retrieving the courses.");
        return
      }

      this.technicalApplicable = technical.value;
      this.courses = courses.value.data.courses;

    })
      .finally(() => {
        this.getUserStats(false);
      })
  },
  methods: {
    modalConfigAction() {
      if (!this.ws) return;

      const message = {
        "type": "SETFREQ",
        "frequency": this.frequencyMs,
      };

      this.ws.send(JSON.stringify(message));
      this.confirmationModal = false;
    },
    resetNotification() {
      this.isNotification = false;
      this.notification = null;
    },
    setupWsInterval() {
      this.wsInterval = setInterval(() => {
        if (this.ws.readyState !== 1) {
          clearInterval(this.wsInterval)
          return
        }

        const message = JSON.stringify({ "type": "DATA" })
        this.ws.send(message);
      }, (60 * this.frequencyMs) * 1000);

    },
    goToChapter(chapterId) {
      this.openLpStartModal(chapterId)
    },
    async startTechnicalEvent() {
      this.$parent.loading = true;
      const eventId = await companyService.startTechnicalEvent()
        .then((resp) => resp.data.event_id)
        .catch((err) => {
          const error = err.response.data
          const manageableError = !!error && !!error.errorDescription && error.errorCode;

          if (!manageableError) return;

          this.flashError(error.errorDescription);
        })
        .finally(() => {
          this.$parent.loading = false;
        });

      if (!eventId) return;

      this.flashSuccess("You're going to be redirected. Please wait")
      setTimeout(() => {

        this.$router.push(`/event/${eventId}`)
      }, 2000)

    },
    async getCourseLabs() {
      this.labLoading = true;
      const courseId = this.courseId;
      return await companyService.getCourseLabs(courseId)
        .then((response) => {
          this.labs = response.data.labs;
          this.labLoading = false;
        })
    },
    async openLpStartModal(chapterId = null) {
      if (!this.userStats) await this.getUserStats(false);

      this.toggleOverlay("userStatsOverlayOpen", { close: true })
      this.toggleOverlay("labOverlay", { close: true })

      const stats = this.userStats;
      const image = this.chosen.image;

      const courses = []

      for (const course of this.courses) {
        courses.push({
          "id": course.id,
          "name": course.name,
          "chapters": course.chapters,
        });
      }

      courses.push({ "id": null, "name": "GLOBAL" });
      const courseId = this.courseId;

      this.$modal.show(
        LearningPathModal,
        { stats, image, chapterId, courses: courses, courseId },
        { height: "auto", width: "80%" }
      )
    },
    async startQuiz() {
      const chapterName = this.chapterName;
      const chapterId = this.courseChapter.id;

      const survey = await userService.getSurvey(chapterId).then(resp => resp.data.questions)
      this.$modal.show(
        SurveyModal,
        {
          survey: survey,
          chapterId: chapterId,
          surveySaved: () => {
            window.location.reload();
          },
          title: chapterName
        },
        { height: "auto", width: "60%" }
      )
    },
    filterLabs(solved = true) {
      const stats = this.userStats;
      if (!stats) return [];
      const labs = stats.labs;
      if (labs.length === 0) return []
      const solveds = {};

      for (const [labId, lab] of Object.entries(labs)) {
        const labSolved = !!lab.solved;
        if (labSolved !== solved) continue;
        const chapterIds = lab.chapters.map(item => item.id)
        if (!this.belongsTo(chapterIds)) continue;
        solveds[labId] = lab;
      }

      return solveds;
    },

    belongsTo(chapterIds) {
      const chapters = Object.values(this.selectedCourseChapters);
      if (chapters.length === 0) return false;

      for (const chapter of chapters) {
        if (chapterIds.every(item => item !== chapter.id)) continue;
        return true;
      }
      return false
    },
    selectCourse() {
      this.courseSelected = this.courseName;
      const chapters = this.selectedCourseChapters;
      this.chapterName = Object.keys(chapters)[0];

      this.toggleOverlay('courseOverlayOpen', { open: true });
    },
    changeActiveTab(tabIdx) {
      this.activeTabIndex = tabIdx;
    },

    async getUserStats(open = true) {
      this.userStatsLoading = true;

      if (open) this.toggleOverlay('userStatsOverlayOpen', { open: true })
      if (!this.userStats) this.$parent.loading = true;

      const userId = this.user.id;
      const data = await companyService.getCompanyUserStats(userId)
        .then((response) => response.data.stats)
        .catch(({ response: error }) => error.data)

      const isError = !!data.errorDescription && !!data.errorCode;
      if (isError) {
        this.toggleOverlay('userStatsOverlayOpen', { close: true })
        this.userStatsLoading = false;
        this.$parent.loading = false;
        const message = data.errorDescription;
        this.flashError(message);
        return
      }

      this.userStats = data;
      this.userStatsLoading = false;

      const courses = this.courses || [];

      if (courses.length > 0) {
        const courseId = courses[0].id;
        this.changeDetailsIndex(courseId, true);
      }

      this.$parent.loading = false;
    },
    async getUserCourseCompletion(courseId = null) {
      return await companyService.getUserCourseCompletion(courseId)
        .then((response) => response.data.courses);
    },
    async selectLabId(labId) {
      this.labSelectedId = labId;
      this.selectedLab = await companyService.getCompanyLabStatsById(labId)
        .then((response) => response.data.stats);
      this.toggleOverlay('labOverlay', { open: true })
    },
    reset() {
      this.labs = []
    },
    toggleOverlay(overlayName, { close = null, open = null }) {
      if (open) {
        this[overlayName] = true;
        return
      }

      if (close) {
        this[overlayName] = false;
        return
      }

      this[overlayName] = !this[overlayName]
    },
    async getAllData() {
      const requests = [
        this.getCourses(),
        this.getTechnicalApplicability(),
      ];

      return await Promise.allSettled(requests);
    },
    async getTechnicalApplicability() {
      return await companyService.getUserTechnicalApplicability()
        .then((resp) => resp.data)
    },
    async getCourses() {
      return await coursesService.getCourses();
    },
    async changeDetailsIndex(courseId, init = false) {
      if (!init) this.courseActive = false;

      this.labOverlay = false;
      this.labSelectedId = null;

      const courses = this.courses;

      if (!courses) return null;
      if (Object.keys(courses).length === 0) return null;


      const [course] = Object.values(courses).filter(course => course.id === courseId)

      if (!course) return;

      this.courseName = course.name;
      this.courseId = course.id;

      this.selectCourse();
      this.getCourseLabs();
      this.userStatsLoading = true;
      this.userCourses = await this.getUserCourseCompletion(this.courseId);
      this.userStatsLoading = false;
    },
    toggleActive() {
      this.isActive = !this.isActive
    },
    async getLabChartData(labId) {
      const userId = this.user.id;
      const data = await companyService.getUserLabChart(labId, userId)
        .then((response) => response.data.chart)
        .catch((error) => error)

      const isError = !!data.errorDescription;
      if (isError) {
        this.flashError(data.errorDescription);
        return
      }

      this.labGraph.prepare(data, { vm: this });
    }
  },
  watch: {
    labSelectedId: function (value) {
      if (!value) return;

      this.getLabChartData(value)
    },
    chapterName: async function (new_) {
      this.showChapterContent = false;
      this.courseChapter = this.selectedCourseChapters[new_]
    },
  }

}

</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Rubik+Mono+One&family=Saira+Semi+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import "../assets/css/colors.scss";

#courses-cards {
  max-height: 80vh;
  overflow-y: scroll;
  padding-bottom: 100px;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

#course-card {
  background: $neutral--darkest;
  cursor: pointer;

  &:hover {
    border: 1px solid $soft-green;
  }
}

#course-card-image-container {
  min-width: 150px;
  max-width: 150px;
  min-height: 150px;
  max-height: 150px;

  object-fit: cover;

  img {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

#course-card-footer {
  flex: 0 2 auto;
  margin-top: auto;
}

#course-completion-bar {
  min-height: 10px;
  background-color: $neutral--dark;
  margin: 0px 10px;
  position: relative;
  overflow: hidden;

  &--progress {

    position: absolute;
    left: 0;
    min-height: 100% !important;
    width: var(--progress) !important;
    background-color: $soft-green !important;
  }
}

#course-right-details {
  min-width: 100% !important;
  min-height: 80vh !important;
  max-height: 80vh !important;
  overflow: hidden;
  padding: 0px !important;
  margin: 0px !important;
  background-color: $neutral--darkest !important;
}

#course-right-banner {
  position: relative;
  overflow: hidden;
  min-height: 100%;
  min-width: 100%;
  object-fit: contain !important;

  img {
    width: 100%;
    height: 100%;

    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
  }
}

#course-right-details-overlay {
  top: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(to bottom, transparent 30% 10%, $neutral--darkest 100% 100%);
  min-width: 100%;
  min-height: 100%;
  height: 100% !important;
  z-index: 10;
}

#course-right-details-wrapper {
  min-width: 100% !important;
  min-height: 100% !important;
  height: 100% !important;
  position: relative;
}

#course-right-details-type-logo {
  max-height: 55px !important;
  background-color: $soft-green !important;
  color: $neutral--darkest !important;
  font-weight: 900;
  width: auto;

  img {
    min-height: 50px !important;
    max-height: 50px !important;
    border-radius: 50%;
    max-width: 50px;
  }
}

#course-right-details-footer {
  margin-top: auto;
}

#course-right-details-main {
  background: linear-gradient(to bottom, transparent 10% 10%, $neutral--darkest 100% 100%);
}

#xmark-icon-container {
  margin-right: auto;
  width: 50px !important;
  height: 50px !important;
  position: relative;

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  &:hover {

    background-color: $neutral--darker;
  }
}

#stats-profile-pic {
  position: relative;
  overflow: hidden;

  border-radius: 50%;
  border: 1px solid white;


  img {
    max-width: 200px !important;
    max-height: 200px !important;
    min-width: 200px !important;
    min-height: 200px !important;
    object-fit: cover;
    width: 100% !important;
    height: 100% !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }
}

#stats-user-details {
  font-weight: 900;

  h2 {
    font-size: 20px;
  }
}

#creation-date {
  font-weight: 100;
}

#tab-item {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
}

#stats-user-tab {
  padding: 10px;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  color: $neutral--soft;

  &.active {
    border-bottom: 1px solid $soft-green;
  }

  &:hover {
    background-color: $neutral--dark;
  }
}

#course-arrow,
#lab-arrow {
  flex: 2 1 auto;
  width: 100%;
  margin-left: auto;
}

#course-menu-item {
  white-space: nowrap;

  h2 {
    line-height: 1;
    font-weight: 500;
    font-size: 16px;
    margin: 0;
    padding: 0;
  }
}

.menu-item {
  cursor: pointer;

  &:nth-child(even) {
    background-color: $neutral--dark;
  }

  &:nth-child(odd) {
    background-color: $neutral--darkest;

  }
}

.arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

#lab-name {
  text-transform: uppercase !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  white-space: nowrap;
}

#graph-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  background-color: rgba($neutral--darkest, 0.4);
  z-index: 10;

}

#lab-name-overlay-content {
  letter-spacing: 2px !important;
  background-color: rgba($neutral--darkest, 0.4);
  padding: 0 !important;
  margin: 0 !important;

  h2 {
    font-size: 26px !important;
    padding: 0 !important;
    margin: 0 !important;
    font-weight: 900 !important;
  }
}

#user-lab-stats-profile-pic,
#user-course-stats-profile-pic {
  min-width: 80px;
  min-height: 80px;
  max-width: 80px;
  max-height: 80px;
  position: relative;

  img {
    min-width: 50px;
    min-height: 50px;
    max-width: 50px;
    max-height: 50px;
    border-radius: 50%;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    object-fit: cover;
    border: 2px solid #fff;
  }
}

#user-lab-stats-username,
#user-course-stats-username {
  white-space: nowrap;
}

#lab-detail-text {
  font-weight: 900;
}

#labs-container {
  position: relative;
  box-sizing: border-box;
  overflow-y: scroll;
  max-height: 50vh;
  overflow-x: scroll;
  padding-bottom: 100px;
}

#xmark-wrapper-lab {
  background-color: rgba($neutral--darkest, 0.4)
}

#propic-nochapters {
  max-width: 200px !important;
  max-height: 200px !important;
  min-width: 200px !important;
  min-height: 200px !important;
  border: 1px solid;
  border-radius: 50%;
  margin-bottom: 10px;
}

#not-complete-xmark {

  max-width: 30px !important;
  max-height: 30px !important;
  min-width: 30px !important;
  min-height: 30px !important;
  text-align: center;
  padding-top: 15px;
}

#lab-actions {
  #green:hover {
    color: $soft-green;
  }

  #red:hover {
    color: $soft-red;
  }

  #orange:hover {
    color: $soft-yellow;
  }

  #blue:hover {
    color: $soft-blue;
  }
}

#chapter-details-scrollable {
  position: relative !important;
  max-height: 100vh !important;
  min-width: 100% !important;

  overflow-x: hidden;
  overflow-y: scroll !important;

}

.height-enter-active,
.height-leave-active {
  transition: height .5s ease-in-out;
  transition: transform .5s ease-in-out;
}

.height-enter,
.height-leave-to {
  transition: transform .5s ease-in-out;
  transform: scaleY(0%);
  height: 0;
}

#graph-overlay-wrapper {
  position: relative;
  min-width: 100% !important;
  min-height: 100% !important;
}

#graph-overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#completion-bars-container {
  white-space: nowrap !important;
  overflow: hidden !important;
  position: relative !important;
  max-height: 100% !important;
}

#completion-bar {
  margin: 0px 10px;
  height: 20px !important;
  width: 100% !important;
  position: relative !important;
  background-color: $neutral--darker !important;
  overflow: hidden;
  border: 1px solid #fff !important;
}

#brain-icon-container {
  &:hover {
    background-color: $neutral--darkest;
  }
}

#brain-icon {
  color: $soft-pink;
}

#completion-bar--progress {
  width: var(--completion-width);
  height: 100%;
  background: linear-gradient(to right,
      #3fffa2 0%,
      #ffdb3a 35%,
      #ff993a 60%,
      #e5405e 100%);
}

#completion-text {
  position: absolute;
  color: #fff !important;
  font-weight: 100;
  color: $neutral--darkest;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#stats-courses {
  max-height: 420px;
  overflow-y: scroll;
}

@media (max-width: 768px) {

  .right-side {
    --shadow-color: rgba(0, 0, 0, 0.9);
    position: fixed !important;
    top: 0% !important;
    left: 100% !important;
    transition: all 500ms ease;
    z-index: 999 !important;
    background-color: var(--shadow-color) !important;

    width: 100% !important;
    height: 100% !important;
    padding-top: 10px;
    margin-top: 100px;


    &.active {
      left: 0% !important;
      margin-top: 100px;
    }
  }

  .left-side {
    margin-top: 100px !important;
  }

  #course-card {
    max-width: 100% !important;
  }

  #course-card-content {
    position: relative !important;
    max-width: 100% !important;
  }

  #course-card-image-container {
    max-width: 100% !important;
    max-height: 100% !important;

    img {

      max-width: 100% !important;
      max-height: 100% !important;
    }
  }

  #course-card-main {
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;

    background-color: rgba(0, 0, 0, 0.5) !important;
  }
}

.technical-quiz-wrapper {
  --technical-container-height: 100px;
  position: absolute;
  top: 0;
  right: 0;
  min-width: 50%;
  min-height: var(--technical-container-height);
  max-height: var(--technical-container-height);
  background-color: $neutral--dark;
  z-index: 100;
  margin: 10px;
  border-left: 2px solid $soft-green;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.technical-quiz-content {
  padding: 3px 10px;
  position: relative;
  min-width: 100%;

  min-height: var(--technical-container-height) !important;
  max-height: var(--technical-container-height) !important;

  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;

  h2 {
    font-weight: 900 !important;
    font-size: 20px !important;

    min-height: inherit !important;

    margin: 0;
    padding: 0;

    line-height: 1;
  }
}

.technical-banner-container {
  flex: 0 1 auto !important;
  margin-bottom: auto !important;
}

.technical-lab-btns-container {

  flex: 0 1 auto !important;

  width: 100%;
  height: 100%;

  margin-top: auto !important;
}

.learningpath-notifications {
  position: absolute;
  overflow-x: hidden;

  bottom: 0;
  right: 0;

  min-width: 0%;
  width: 0%;

  z-index: 1000;
  padding: 20px 0px;

  background-color: black;
  border-left: 0px solid $soft-green;


  &.active {
    border-left: 2px solid $soft-green;
    transition: all 100ms linear;

    min-width: 100%;
    width: 100%;
  }

  transition: all 100ms linear;
}

.learningpath-notifications-wrapper {
  position: relative;
  padding: 0px 20px;
}

.learningpath-notifications-content {
  display: flex;
  justify-content: between;
}

.confirm-overlay-wrapper {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
}

.confirm-overlay-background {
  top: 0;
  left: 0;
  position: absolute;
  background-color: black;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: 99;
}

.confirm-overlay {
  position: absolute;
  width: 100%;
  transform: translate(35%, 25%);
  height: 100%;
  z-index: 100;
}

.confirmation-modal {
  width: 600px;
}
</style>
