<template>
    <div class="lab-reset-modal">
        <h1 class="main-title-1">Lab Reset Request</h1>
        <div class="flex-column lab-reset-data d-flex justify-content-center align-items-center py-2">
            <img class="requester-image" :src="lab.user.profile_pic" :alt="lab.user.username">
            <h1 class="text-uppercase main-title-1">{{ lab.name }}</h1>
            <p>Requested by <b class="text-uppercase">{{ lab.user.username }}</b> <span
                    v-if="lab.team !== null && lab.team !== undefined"> - {{
                        lab.team.name
                    }}</span></p>
            <div :style="`--width: ${expiring}%`" class="lab-reset-progress-bar"></div>
            <p class="mt-1 text-uppercase">not answering means you're accepting the lab reset</p>

            <div class="buttons d-flex justify-content-around align-items-center flex-row">
                <px-button @click.native="userAnswer(true)" class="px-button--success outline mx-2">Accept</px-button>
                <px-button @click.native="userAnswer(false)" class="px-button--danger outline mx-2">Reject</px-button>
            </div>
        </div>
    </div>
</template>

<script>
import PxButton from "@/components/PxButton";
import eventService from "@/api/event.js";

export default {
    name: "EventLabResetConfirmModal",
    data() {
        return {
            expiring: 100,
            interval: null,
            response: true,
        }
    },
    components: {
        PxButton,
    },
    created() {
        this.interval = setInterval(() => {
            this.expiring = Math.max(0, this.expiring - 0.1);
            if (this.expiring === 0) {
                clearInterval(this.interval);
                this.selfClose();
            }
        }, 100)
    },
    props: {
        lab: {
            type: Object,
            required: true,
        },
        resetId: {
            type: Number,
            required: true,
        },
        callback: {
            type: Function,
            required: true,
        }
    },
    methods: {
        selfClose() {
            this.$emit("close");
        },
        userAnswer(answer) {
            this.response = answer;
            this.selfClose();
        },
        sendResetResponse() {
            const resetId = this.resetId;
            const response = this.response;
            return eventService.eventLabResetResponse(resetId, response)
                .then((response) => {
                    const manageableMessage = !!response.data && !!response.data.message;
                    if (manageableMessage) {
                        this.flashSuccess(response.data.message);
                    }
                })
        }
    },
    beforeDestroy() {
        console.log("Destroying");
        clearInterval(this.interval);
        this.sendResetResponse();
        this.callback();
    },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/colors.scss";

.lab-reset-modal {
    background-color: $neutral--dark;
    padding: 30px;

    h1 {
        margin: 0 !important;
        padding: 0 !important;
        line-height: 1;
        text-transform: uppercase;
    }
}

.requester-image {
    max-width: 100px;
    min-width: 100px;
    max-height: 100px;
    min-height: 100px;

    border-radius: 50%;
    border: 1px solid white;

    object-fit: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    margin-bottom: 50px;

    img {
        max-width: 100px;
        min-width: 100px;
        max-height: 100px;
        min-height: 100px;
    }
}

.lab-reset-progress-bar {
    width: 100%;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 5px;
    background-color: $neutral--darkest;
    overflow: hidden;
}

.lab-reset-progress-bar::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: var(--width, 100%);
    border-radius: 5px;
    background-color: $soft-green;
    transition: width 0.1s linear;
}
</style>
